import React, {useState, useEffect, useCallback} from 'react'
import SEO from '../components/seo'
import Container from '../components/container'
import Layout from '../components/layout'
import {css} from '@emotion/core'
import Link from '../components/link'
import theme from '../../config/theme'
import {bpMaxMD, bpMaxSM} from '~lib/breakpoints'
import {rhythm} from '~lib/typography'

export function AllNames({allKeywords, pathPrefix, name}) {
  return (
    <div
      css={css`
        padding-top: 0;
      `}
    >
      <div
        css={css`
          h1 {
            font-size: 1.25rem;
            font-family: sans-serif;
          }
        `}
      >
        <h1>🔠 全部{name}</h1>
      </div>
      <ul
        css={css`
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          li {
            padding: 0 3px;
            font-size: 20px;
            a {
              font-size: 15px;
            }
            span {
              font-size: 12px;
            }
          }
        `}
        className="with-mark-link"
      >
        {Object.keys(allKeywords).map((word, i) => (
          <li key={word}>
            <a href={`${pathPrefix}/#${encodeURIComponent(word)}`}>
              {word} <span>[{allKeywords[word]}]</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function ArchivesPage({title, allMdx, keyName}) {
  const allArchives = {}
  allMdx.edges.forEach(({node}, i) => {
    if (node.frontmatter[keyName]) {
      node.frontmatter[keyName].forEach(word => {
        if (!allArchives[word]) allArchives[word] = 0
        allArchives[word]++
      })
    }
  })

  const [queryHash, setQueryHash] = useState('')

  useEffect(() => {
    const handler = () => {
      const hash = location.hash.replace(/^#/, '')
      setQueryHash(decodeURIComponent(hash))
    }

    window.addEventListener('hashchange', handler)

    handler()

    return () => {
      window.removeEventListener('hashchange', handler)
    }
  }, [queryHash])

  const updateHash = useCallback(
    hash => {
      location.hash = hash
      setQueryHash(hash)
    },
    [queryHash],
  )

  return (
    <Layout headerColor={theme.colors.white} logo={false}>
      <SEO />
      <Container
        css={css`
          margin-top: 20px;
          position: relative;
          padding-bottom: 0;
          background: var(--bgcolor-post);
          border-radius: 5px;
          padding: 40px 80px 60px 80px;
          margin-bottom: ${rhythm(1)};
          ${bpMaxMD} {
            padding: auto;
          }
          ${bpMaxSM} {
            border-radius: 0;
          }
          h2 {
            margin-bottom: ${rhythm(1.5)};
          }
        `}
        className="with-mark-link"
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            h1 {
              font-size: 1.25rem;
              font-family: sans-serif;
            }
          `}
        >
          <h1>{queryHash == '' ? `全部${title}` : `全部“${queryHash}”文章`}</h1>
          {queryHash != '' && (
            <a href="#" onClick={() => updateHash('')}>
              查看全部列表
            </a>
          )}
        </div>
        <div
          css={css`
            list-style: none;
            margin: 0;
          `}
          className="with-mark-link"
        >
          {queryHash == '' ? (
            Object.keys(allArchives).map((word, i) => (
              <ArchiveArticles
                key={word}
                word={word}
                edges={allMdx.edges}
                keyName={keyName}
                updateHash={updateHash}
              />
            ))
          ) : (
            <ArchiveArticles
              key={queryHash}
              word={queryHash}
              edges={allMdx.edges}
              keyName={keyName}
              updateHash={updateHash}
            />
          )}
        </div>
      </Container>
      <Container>
        {queryHash != '' && (
          <AllNames allKeywords={allArchives} pathPrefix="/keywords" name={title} />
        )}
      </Container>
    </Layout>
  )
}
function ArchiveArticles({word, edges, keyName, updateHash}) {
  return (
    <div key={word}>
      <h2 id={encodeURIComponent(word)} onClick={() => updateHash(word)}>
        {word}
      </h2>
      <div
        css={css`
          padding-left: 15px;
        `}
      >
        {edges
          .filter(mdx => mdx.node.frontmatter[keyName].includes(word))
          .map(({node: post}) => (
            <div
              key={post.id}
              css={css`
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: spcae-between;
                justify-content: space-between;
                h3 {
                  padding: 0;
                  margin: 0;
                  font-weight: normal;
                  border-bottom: 1px solid #eee;
                  :hover {
                    border-color: #888;
                  }
                }
              `}
            >
              <span
                css={css`
                  min-width: 0;
                  overflow: hidden;
                  flex: 1;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-right: 15px;
                `}
              >
                <Link to={post.fields.slug} aria-label={`View ${post.frontmatter.title}`}>
                  {post.frontmatter.title}
                </Link>
              </span>

              <div
                css={css`
                  font-size: 14px;
                  color: #8e8e8e;
                `}
              >
                <div>{post.fields.datetime}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
