function paddingZero(num) {
  return num.toString().padStart(2, '0')
}

function isAfter(now, time) {
  return now.getTime() > time.getTime()
}

/**
 *
 * @param {Date} date
 * @param {boolean} alwaysWithTime
 */
export default function timeAgoFormat(date, alwaysWithTime = true) {
  if (!date instanceof Date) {
    return '- invalid time -'
  }
  const now = new Date()
  var elapsed = now.getTime() - date.getTime()

  let isToday = isAfter(
    date,
    new Date(now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + now.getDate() + ' 00:00:00'),
  )
  let isYesterday = isAfter(
    date,
    new Date(
      now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + (now.getDate() - 1) + ' 00:00:00',
    ),
  )
  let isThisYear = isAfter(date, new Date(now.getFullYear() + '/1/1 00:00:00'))

  let seconds = elapsed / 1000
  let minutes = seconds / 60
  let hours = minutes / 60

  let result
  if (seconds < 45) result = '刚刚'
  else if (seconds < 60) result = '1分钟前'
  else if (minutes < 60) result = `${Math.round(minutes)}分钟前`
  else if (hours < 12) result = `${Math.round(hours)}小时前`
  else {
    let timeStr = ' ' + paddingZero(date.getHours()) + ':' + paddingZero(date.getMinutes())
    if (isToday) {
      result = '今天' + timeStr
    } else if (isYesterday) {
      result = '昨天' + timeStr
    } else if (isThisYear) {
      result = paddingZero(date.getMonth() + 1) + '-' + paddingZero(date.getDate())
      if (alwaysWithTime == true) result += timeStr
    } else {
      result =
        date.getFullYear().toString() +
        '-' +
        paddingZero(date.getMonth() + 1) +
        '-' +
        paddingZero(date.getDate())
      if (alwaysWithTime == true) result += timeStr
    }
  }

  return ' ' + result
}

export function timeStr(now) {
  if (!now instanceof Date) {
    return '- invalid time -'
  }
  let timeStr = ' ' + paddingZero(now.getHours()) + ':' + paddingZero(now.getMinutes())
  return now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + now.getDate() + timeStr
}
