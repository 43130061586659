import React from 'react'
import { css } from '@emotion/core'
import Link from '../link'
import { bpMaxSM } from '~lib/breakpoints'
import Container from '../container'
import timeAgoFormat, { timeStr } from '../../utils/timeago'

import { fetchRestApi } from '~lib/leancloud'

// -废弃
// 通过这个更新记录：`https://service-10gf6kp5-1252337231.bj.apigw.tencentcs.com/release/db-share-reading?title=[title]&originalUrl=[url]`
// 最近 10 条记录： https://db-1252337231.cos.ap-beijing.myqcloud.com/recent-reading-10.json
// 全部记录：https://db-1252337231.cos.ap-beijing.myqcloud.com/recent-reading.json

// 目前使用 leancloud
// https://leancloud.cn/dashboard/data.html?appid=lAmGU3KrKL17OlruaSSK6iqN-gzGzoHsz#/
const recentReading10 = `https://api-leancloud.ubug.io/1.1/classes/recent_reading?order=-updatedAt&&`

export default class RecentReading extends React.Component {
  state = {
    entryList: [],
  }
  componentDidMount() {
    const limit = this.props.hideAll ? '' : 'limit=10&&'
    try {
      fetchRestApi(`${recentReading10}${limit}?${new Date().toDateString()}`).then(response => {
        this.processData(response)
      })
    } catch (error) { }
  }
  processData = response => {
    if(response.results && Array.isArray(response.results))
    this.setState({ entryList: response.results })
  }
  render() {
    if (this.state.entryList && this.state.entryList.length == 0) return null
    return (
      <Container
        css={css`
          margin-top: -40px;
        `}
      >
        <h3
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <span>👍 我最近点赞的文章</span>
          {this.props.hideAll ? null : (
            <span
              css={css`
                text-align: center;
                font-size: 15px;
              `}
            >
              <Link to="/readings">查看全部最近阅读</Link>
            </span>
          )}
        </h3>
        <ul className="with-mark-link">
          {this.state.entryList.map((entry, i) => {
            const time = new Date(entry.time.iso)
            return (
              <li
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
                key={entry.objectId}
              >
                <span
                  css={css`
                    min-width: 0;
                    overflow: hidden;
                    flex: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: 15px;
                  `}
                >
                  <a rel="noopener noreferrer" target="_blank" href={entry.originUrl}>
                    {entry.type ? `[${entry.type}] ` : ''}
                    {entry.title}
                  </a>
                </span>
                <span title={timeStr(time)}>{timeAgoFormat(time)}</span>
              </li>
            )
          })}
        </ul>
      </Container>
    )
  }
}
