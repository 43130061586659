import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Link from '../components/link'
import Container from '../components/container'
import Hero from '../components/big-hero'
import SimpleCategory from '../components/simple-cat'
import SimpleKeywords from '../components/simple-keywords'
import RecentReading from '../components/recent-reading/recent-reading'
import Markdown from 'react-markdown'
import { AllNames } from '../templates/archives'
import theme from '../../config/theme'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'
import { rhythm } from '~lib/typography'

import studioImg from '../images/studio.svg'
import talksImg from '../images/talks.svg'
import minutesImg from '../images/3-minutes.svg'
// import devtipsImg from '../images/devtips.svg'

const Card = ({ backgroundColor = '#E75248', image, title, description, link, big = false }) => (
  <Link
    to={link}
    aria-label={`View ${title}`}
    css={css`
      * {
        color: white;
        margin: 0;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-size: 22px;
        padding: 40px 10px 0 40px;
      }
      p {
        padding: 20px 40px 0 40px;
        font-size: 16px;
        opacity: 0.85;
        ${bpMaxSM} {
          padding: 20px 20px 0 40px;
        }
      }
      /*----------------*/
        align-items: flex-start;
      flex-direction: column;
      img {
        margin-top: 20px;
      }
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      img {
        width: 33%;
      }
      ${bpMaxMD} {
        img {
          width: 13%;
        }
      }
      h4 {
        padding: 0 0 0 0;
      }
      /*----------------*/
      background: ${backgroundColor};
      overflow: hidden;
      border-radius: 5px;
      margin: 0 30px;
      margin-bottom: 10px;
      img {
        transition: ${theme.transition.ease};
      }
      @media (hover: hover) {
        :hover:not(.touch) {
          transform: scale(1.03);
          box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.15);
        }
      }
    `}
  >
    <div>
      <h4>{title}</h4>
      {description && <p>{description}</p>}
    </div>
    <img src={image} alt={title} />
  </Link>
)

const PostTitle = styled.h3`
  color: var(--color-anchor);
  margin-bottom: ${rhythm(0.3)};
  transition: ${theme.transition.ease};
  font-size: 22px;
  :hover {
    color: ${theme.brand.primary};
    transition: ${theme.transition.ease};
  }
`

const Description = styled.div`
  margin-bottom: 10px;
  display: inline-block;
`

export default function Index({ data: { allMdx } }) {
  const allArchives = {}
  allMdx.edges.forEach(({ node }, i) => {
    if (node.frontmatter.keywords) {
      node.frontmatter.keywords.forEach(word => {
        if (!allArchives[word]) allArchives[word] = 0
        allArchives[word]++
      })
    }
  })

  return (
    <Layout headerColor={theme.colors.white} hero={<Hero />}>
      <SEO />
      <Container
        css={css`
          margin-top: -20px;
          position: relative;
          padding-bottom: 0;
          background: var(--bgcolor-post);
          border-radius: 5px;
          padding: 40px 80px 60px 80px;
          margin-bottom: ${rhythm(1)};
          ${bpMaxMD} {
            padding: auto;
          }
          ${bpMaxSM} {
            border-radius: 0;
          }
          h2 {
            margin-bottom: ${rhythm(1.5)};
          }
        `}
      >
        <h2>📃 文章</h2>
        {allMdx.edges.slice(0, 10).map(({ node: post }) => (
          <div
            key={post.id}
            css={css`
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 1px solid var(--color-line);
            `}
          >
            <Link to={post.fields.slug} aria-label={`View ${post.frontmatter.title}`}>
              <PostTitle>{post.frontmatter.title}</PostTitle>
            </Link>
            <div
              css={css`
                font-size: 14px;
                color: #8e8e8e;
                margin-bottom: 10px;
                word-break: break-all;
                white-space: normal;
              `}
            >
              <span>{post.fields.datetime}</span>
              <SimpleCategory categories={post.fields.categories} />
              <SimpleKeywords keywords={post.frontmatter.keywords} />
            </div>
            <Description
              css={css`
                p:last-child {
                  margin-bottom: 0px;
                }
              `}
            >
              {post.fields.description ? (
                <Markdown>{post.fields.description}</Markdown>
              ) : (
                  post.excerpt
                )}{' '}
            </Description>
            {/* <div>
              <Link
                to={post.fields.slug}
                aria-label={`View ${post.frontmatter.title}`}
              >
                查看 →
              </Link>
            </div> */}
            <span />
          </div>
        ))}
        <div
          css={css`
            text-align: center;
          `}
        >
          <Link to="/blogs" aria-label="Visit blog page">
            📃 查看全部文章
          </Link>
        </div>
      </Container>

      <Container>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            ${bpMaxSM} {
              flex-direction: column;
            }
          `}
        >
          <Card
            title="💬 闲说"
            backgroundColor={theme.colors.blue}
            image={talksImg}
            link="/talks"
          />
          <Card
            backgroundColor={theme.colors.purple}
            title="🚧 工具 / 项目"
            image={studioImg}
            link="/studio"
          />
          <Card
            title="👍 关于我"
            backgroundColor={theme.colors.yellow}
            image={minutesImg}
            link="/about"
          />
        </div>
      </Container>

      <Container
        css={css`
          margin-top: -20px;
        `}
      >
        <AllNames
          css={css`
            padding: 0 40px;
          `}
          allKeywords={allArchives}
          pathPrefix={'/keywords'}
          name={'关键词'}
        />
      </Container>

      <RecentReading />
    </Layout>
  )
}

// allMdx(
//   limit: 10
//   sort: {fields: [frontmatter___date], order: DESC}
//   filter: {
//     frontmatter: {published: {ne: false}, unlisted: {ne: true}}
//     fileAbsolutePath: {regex: "//content/blog//"}
//   }
export const pageQuery = graphql`
  query {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {
        frontmatter: {published: {ne: false}, unlisted: {ne: true}}
        fileAbsolutePath: {regex: "//content/blog//"}
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
            datetime
            description
            categories
            keywords
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            keywords
          }
        }
      }
    }
  }
`
