import React from 'react'
import { css } from '@emotion/core'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'
import { rhythm } from '~lib/typography'
import Markdown from 'react-markdown'
import Container from './container'
import GradientBg from './hoverGradientBg'

function Hero({
  children,
  title = `我是 Ubug，一名开发工程师👨‍💻，用高质量的技术和代码改变世界。`,
  text,
  bigEmoji,
  noImage = false,
}) {
  return (
    <GradientBg>
      {children}
      <Container
        css={css`
          position: relative;
          display: flex;
          height: 256px;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          //justify-content: center;
          padding: 60px 40px 0;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <h1
            css={css`
              position: relative;
              z-index: 5;
              line-height: 1.5;
              margin: 0;
              max-width: 28rem;
              font-size: 25px;
              height: 100%;
              display: flex;
            `}
          >
            {title}
          </h1>
          {text && (
            <Markdown
              css={css`
                padding-bottom: 30px;
                p {
                  color: hsla(255, 100%, 100%, 0.9);
                }
                max-width: 28rem;
                margin-bottom: 0;
                margin-top: ${rhythm(0.5)};
                a {
                  text-decoration: underline;
                  color: hsla(255, 100%, 100%, 1);
                  :hover {
                    color: hsla(255, 100%, 100%, 0.9);
                  }
                }
                p:last-child {
                  margin-bottom: 0;
                }
              `}
            >
              {text}
            </Markdown>
          )}
          {bigEmoji && (
            <div
              css={css`
                position: absolute;
                bottom: 0;
                right: 100px;
                font-size: 100px;
                text-shadow: 0px 20px 20px #00000057;
                ${bpMaxMD} {
                  display: none;
                }
              `}
            >
              {bigEmoji}
            </div>
          )}
        </div>
      </Container>
    </GradientBg>
  )
}

export default Hero
